<template>
<div class="settings-page">
  <div class="page__header" :style="{ marginBottom: '12px' }">
    <div class="header-title">班級成員</div>
  </div>

  <div class="page__body" :style="{ marginBottom: '24px' }">
    <a-tabs
      v-model:activeKey="selectedKey"
      @change="handleTabChange"
      type="card"
      :style="{ marginBottom: '12px' }"
    >
      <a-tab-pane key="user" tab="學員" />
      <a-tab-pane key="assistant" tab="助教" />
      <a-tab-pane key="coach" tab="教練" />
    </a-tabs>

    <div class="body-transfer" v-if="!loading">
      <a-row>
        <a-col :lg="12" :xs="24">
          <a-transfer
            v-model:target-keys="targetMemberIds"
            :data-source="membersData"
            :rowKey="record => record.member_id"
            :filter-option="filteredOptions"
            :render="item => item.name"
            :locale="{
              itemUnit: '成員',
              itemsUnit: '成員',
              searchPlaceholder: '輸入姓名或會員編號'
            }"
            @change="handleChange"
            @search="handleSearch"
            show-search
            :list-style="{
              width: '100%',
              height: '50vh',
            }"
          />
        </a-col>
      </a-row>
    </div>

    <!-- Loading -->
    <template v-if="loading">
      <div align="center" :style="{ padding: '40px 0' }">
        <a-spin tip="載入資料中..." :style="{ margin: '0 auto' }" />
      </div>
    </template>
    <!-- Loading -->
  </div>

  <div class="page__actions">
    <a-button
      key="submit"
      type="primary"
      size="large"
      :loading="updating"
      @click="handleOk"
    >確認更新</a-button>
  </div>
</div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import api from '@/apis'

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();

    /* Loading */
    const loading = ref(false);
    const updating = ref(false);

    /* Tab */
    const selectedKey = ref('user');
    const handleTabChange = (async () => {
      loading.value = true;

      if (selectedKey.value == 'user') await getUsersData();
      else if (selectedKey.value == 'assistant') await getAssistantsData();
      else if (selectedKey.value == 'coach') await getCoachesData();

      // get course's members
      await getCourseMembers(selectedKey.value);
      targetMemberIds.value = courseMembersData.value.map(item => item.member_id);

      loading.value = false;
    });

    /* Data */
    const membersData = ref([]); // 所有成員
    const courseMembersData = ref([]); // 此班級的成員
    const targetMemberIds = ref([]);

    /* Api */
    const getUsersData = (async () => {
      membersData.value = [];
      
      const response = await api.v1.user.list();
      response.data.result.forEach(item => {
        membersData.value.push({
          name: item.name,
          member_id: item.member_id
        });
      });
    });

    const getAssistantsData = (async () => {
      membersData.value = [];
      
      const response = await api.v1.assistant.list();
      response.data.result.forEach(item => {
        membersData.value.push({
          name: item.name,
          member_id: item.member_id
        });
      });
    });

    const getCoachesData = (async () => {
      membersData.value = [];
      
      const response = await api.v1.coach.list();
      response.data.result.forEach(item => {
        membersData.value.push({
          name: item.name,
          member_id: item.member_id
        });
      });
    });

    const getCourseMembers = (async (memberType) => {
      courseMembersData.value = [];

      const response = await api.v1.course.findMembers(memberType, {
        course_id: route.params.course_id
      });
      courseMembersData.value = response.data.result;
    });

    /* Filter functions */
    const filteredOptions = (input, option) => {
      return option.name.includes(input) || option.member_id.includes(input)
    }
    const handleChange = (keys, direction, moveKeys) => {
      console.log(keys, direction, moveKeys);
    }
    const handleSearch = (dir, value) => {
      console.log('search:', dir, value);
    }

    /* Mounted */
    onMounted(async () => {
      handleTabChange();
    });

    return {
      /* Loading */
      updating,

      /* Tab */
      selectedKey,
      handleTabChange,

      /* Data */
      membersData,
      courseMembersData,
      targetMemberIds,

      /* Api */
      getUsersData,
      getAssistantsData,
      getCoachesData,
      getCourseMembers,

      /* Filter functions */
      filteredOptions,
      handleChange,
      handleSearch,

      /* Antd */
      message
    }
  },
  methods: {
    async handleOk() {
      try {
        this.updating = true;

        await api.v1.course.joinMembers(this.selectedKey, {
          course_id: this.$route.params.course_id,
          ids: this.targetMemberIds
        });

        this.updating = false;
        message.success('編輯成員成功');
      } catch (error) {
        message.error('編輯成員失敗，請稍後再試');
        console.log(error);
      }
    }
  }
})
</script>