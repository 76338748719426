<template>
<div class="settings-page">
  <div class="page__header">
    <div class="header-title">上課時段</div>
  </div>

  <div class="page__body">
    <a-form
      :model="courseData"
      layout="vertical"
      autocomplete="off"
    >
      <a-row>
        <a-col :lg="12" :xs="24">
          <a-form-item
            label="班級開課日期區間"
            name="tempDateRange"
          >
            <a-range-picker
              v-model:value="tempDateRange"
              @change="onChangeDateRange"
              format="YYYY-MM-DD"
              size="large"
              :style="{ width: '100%' }"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :lg="12" :xs="24">
          <a-form-item
            label="每週上課日"
            name="days"
            :rules="[{ required: true, message: '請選擇上課日' }]"
          >
            <a-checkbox-group
              v-model:value="courseData.days"
              :options="dayOptions"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :lg="6" :xs="12">
          <a-form-item
            label="課堂開始時間"
            name="start_time"
            :rules="[{ required: true, message: '請選擇課堂開始時間' }]"
          >
            <a-time-picker
              v-model:value="tempStartTime"
              @change="onChangeStartTime"
              :show-time="{ format: 'HH:mm' }"
              format="HH:mm"
              size="large"
              :style="{ width: '100%' }"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :xs="12">
          <a-form-item
            label="課堂結束時間"
            name="end_time"
            :rules="[{ required: true, message: '請選擇課堂結束時間' }]"
          >
            <a-time-picker
              v-model:value="tempEndTime"
              @change="onChangeEndTime"
              :show-time="{ format: 'HH:mm' }"
              format="HH:mm"
              size="large"
              :style="{ width: '100%' }"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>

  <div class="page__actions">
    <a-button
      key="submit"
      type="primary"
      size="large"
      :loading="updating"
      @click="handleOk"
    >確認更新</a-button>
  </div>
</div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import api from '@/apis'

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();

    /* Loading */
    const updating = ref(false);

    /* Data */
    const courseData = ref({});

    /* Datetime Picker */
    const tempDateRange = ref([]);
    const tempStartTime = ref('');
    const tempEndTime = ref('');

    /* Options */
    const dayOptions = ref([{
      label: '禮拜ㄧ',
      value: '1'
    }, {
      label: '禮拜二',
      value: '2'
    }, {
      label: '禮拜三',
      value: '3'
    }, {
      label: '禮拜四',
      value: '4'
    }, {
      label: '禮拜五',
      value: '5'
    }, {
      label: '禮拜六',
      value: '6'
    }, {
      label: '禮拜日',
      value: '7'
    }]);

    /* Api */
    const getCourseData = (async () => {
      const response = await api.v1.course.findOne(route.params.course_id);
      courseData.value = response.data.result;
    });

    /* Mounted */
    onMounted(async () => {
      await getCourseData();

      if (courseData.value.start_date && courseData.value.end_date) {
        tempDateRange.value = [dayjs(courseData.value.start_date, 'YYYY-MM-DD'), dayjs(courseData.value.end_date, 'YYYY-MM-DD')];
      }

      if (courseData.value.start_time) {
        tempStartTime.value = dayjs(courseData.value.start_time, 'HH:mm');
      }

      if (courseData.value.end_time) {
        tempEndTime.value = dayjs(courseData.value.end_time, 'HH:mm');
      }
    });

    return {
      /* Loading */
      updating,
      
      /* Data */
      courseData,

      /* Datetime Picker */
      tempDateRange,
      tempStartTime,
      tempEndTime,

      /* Options */
      dayOptions
    }
  },
  methods: {
    async handleOk() {
      const err = this._checkRequried();
      if (err) {
        message.error(err);
        return
      }

      this.updating = true;

      try {
        await api.v1.course.updateOne(this.courseData.id, {
          start_date: this.courseData.start_date,
          end_date: this.courseData.end_date,
          days: this.courseData.days,
          start_time: this.courseData.start_time,
          end_time: this.courseData.end_time
        });
      } catch (error) {
        console.log(error);
        message.error('更新上課時段失敗');
        this.$emit('cancel');
        return
      } finally {
        this.updating = false;
      }

      message.success('更新上課時段成功');
    },

    /* Datetime Picker */
    onChangeDateRange(value) {
      if (value) {
        this.courseData.start_date = dayjs(value[0]).format('YYYY-MM-DD');
        this.courseData.end_date = dayjs(value[1]).format('YYYY-MM-DD');
      } else {
        this.courseData.start_date = '';
        this.courseData.end_date = '';
      }
    },
    onChangeStartTime(value) {
      if (value) this.courseData.start_time = dayjs(value).format('HH:mm');
      else this.courseData.start_time = '';
    },
    onChangeEndTime(value) {
      if (value) this.courseData.end_time = dayjs(value).format('HH:mm');
      else this.courseData.end_time = '';
    },

    /* Private func */
    _checkRequried() {
      let err = '';
      
      if (this.courseData.days.length == 0) {
        err = '請輸入每週上課日';
      }
      else if (!this.courseData.start_time) {
        err = '請輸入課堂開始時間';
      }
      else if (!this.courseData.end_time) {
        err = '請輸入課堂結束時間';
      }

      return err
    }
  }
})
</script>