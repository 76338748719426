<template>
  <!-- Layout Header -->
  <a-layout-header class="layout-header" :style="{ height: device.mobile ? '50px' : '74px' }">
    <a-row>
      <a-col :span="24">
        <div class="letwecare-breadcrumb">
          <a-breadcrumb
            v-if="!device.mobile"
            class="__breadcrumb"
            :routes="breadcrumbRoutes"
          >
            <template #itemRender="{ routes, route }">
              <span v-if="routes.indexOf(route) === routes.length - 1">
                {{ route.breadcrumbName }}
              </span>
              <router-link v-else :to="route.path">
                {{ route.breadcrumbName }}
              </router-link>
            </template>
          </a-breadcrumb>

          <a-page-header
            class="__page-header"
            style="height: 63px; padding-left: 0;"
            :title="`${courseData.name}：班級設定`"
            @back="backToCoursePage"
          >
            <template #itemRender="{ route, params, routes, paths }">
              <span v-if="routes.indexOf(route) === routes.length - 1">{{route.breadcrumbName}}</span>
              <router-link v-else :to="paths.join('/')">{{route.breadcrumbName}}</router-link>
            </template>
          </a-page-header>
        </div>
      </a-col>
    </a-row>
  </a-layout-header>
  <!-- Layout Header -->

  <!-- Layout Nested -->
  <a-layout class="settings-layout app-layout-nested">
    <!-- Sider -->
    <a-layout-sider
      class="layout-sider"
      v-model:collapsed="collapsed"
      :collapsed-width="0"
      :collapsible="device.mobile"
      :trigger="device.mobile ? null : ''"
      :defaultCollapsed="device.mobile"
      :style="{ padding: device.mobile ? '8px 0' : '24px' }"
    >
      <a-menu
        class="settings-sidebar-menu"
        v-model:selectedKeys="selectedKeys"
        @click="handleSidebarMenuClick"
        mode="inline"
      >
        <a-menu-item class="menu-item" key="info">
          <span class="item-text">班級資料</span>
        </a-menu-item>
        <a-menu-item class="menu-item" key="timeRange">
          <span class="item-text">上課時段</span>
        </a-menu-item>
        <a-menu-item class="menu-item" key="members">
          <span class="item-text">班級成員</span>
        </a-menu-item>
        <a-menu-item class="menu-item" key="survey">
          <span class="item-text">問卷調查</span>
        </a-menu-item>
        <a-menu-item class="menu-item" key="advanced">
          <span class="item-text">進階設定</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <!-- Sider -->
    
    <!-- Content -->
    <a-layout-content class="layout-nested-content" :style="{ background: 'white' }">
      <div
        class="content__body fluid"
        :style="{ padding: device.mobile ? '8px' : '24px' }"
      >
        <div v-if="device.mobile" :style="{ padding: '0 6px' }">
          <a-button type="text" @click="collapsed = !collapsed">
            <template #icon><MenuOutlined :style="{ fontSize: '1.1rem' }" /></template>
          </a-button>
        </div>

        <template v-if="selectedKeys[0] == 'info'">
          <Info />
        </template>

        <template v-if="selectedKeys[0] == 'timeRange'">
          <TimeRange />
        </template>

        <template v-if="selectedKeys[0] == 'members'">
          <Members />
        </template>

        <template v-if="selectedKeys[0] == 'survey'">
          <Survey />
        </template>

        <template v-if="selectedKeys[0] == 'advanced'">
          <Advanced />
        </template>
      </div>
    </a-layout-content>
    <!-- Content -->
  </a-layout>
  <!-- Layout Nested -->
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { MenuOutlined } from '@ant-design/icons-vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import { useAuthStore } from '@/store/auth'
import api from '@/apis'
import Info from './Info.vue'
import TimeRange from './TimeRange.vue'
import Members from './Members.vue'
import Survey from './Survey.vue'
import Advanced from './Advanced.vue'

export default defineComponent({
  components: {
    Info,
    TimeRange,
    Members,
    Survey,
    Advanced,
    MenuOutlined
  },
  setup() {
    const route = useRoute();
    const device = createDeviceDetector();

    /* Store */
    const authStore = useAuthStore();

    /* Data */
    const courseData = ref({});

    /* Menu & Sider */
    const selectedKeys = ref(['info']);
    const collapsed = ref(false);
    if (device.mobile) collapsed.value = true;

    const handleSidebarMenuClick = () => {
      if (device.mobile) {
        collapsed.value = !collapsed.value;
      }
    }

    /* Api */
    const getCourseData = (async () => {
      courseData.value = {};
      
      const response = await api.v1.course.findOne(route.params.course_id);
      courseData.value = response.data.result;
      console.log(courseData.value);
    });

    /* Routes breadcrumb */
    const breadcrumbRoutes = ref([
      {
        path: '/app/courses',
        breadcrumbName: '班級管理'
      }
    ]);

    /* Mounted */
    onMounted(async () => {
      await getCourseData();

      // set routes breadcrumb
      breadcrumbRoutes.value.push({
        path: `/app/course/${ route.params.course_id }`,
        breadcrumbName: courseData.value.name
      });
      breadcrumbRoutes.value.push({
        path: `/app/course/${ route.params.course_id }/settings`,
        breadcrumbName: '班級設定'
      });
    });

    return {
      device,

      /* Routes breadcrumb */
      breadcrumbRoutes,

      /* Data */
      courseData,

      /* Menu & Sider */
      selectedKeys,
      collapsed,
      handleSidebarMenuClick
    }
  },
  methods: {
    /* router */
    backToCoursePage() {
      this.$router.push({
        name: 'AppCoursePage',
        params: {
          course_id: this.$route.params.course_id
        }
      });
    }
  }
})
</script>

<style lang="scss">
.settings-layout {
  .layout-sider {
    background: #FAFAFA !important;
  }
  
  .settings-sidebar-menu {
    height: calc(100vh - 115px);
    border-right: none;
    background: #FAFAFA !important;

    .menu-item {
      height: 32px;
      background: #FAFAFA !important;

      &::after {
        display: none;
      }

      .item-text {
        color: #424242;
        font-size: 1.05rem;
        font-weight: 400;
      }

      &.ant-menu-item-selected {
        .item-text {
          color: #1890ff;
        }
      }
    }
  }
}

/* Component Page */
.settings-page {
  padding: 0 12px;
  
  .page__header {
    margin-bottom: 24px;

    .header-title {
      font-size: 1.5rem;
      font-weight: 400;
    }
  }

  .page__body {
    margin-bottom: 12px;
  }
}
</style>