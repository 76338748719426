import { defineStore } from 'pinia'
import moment from 'moment'

export const useSemesterStore = defineStore('semester', {
  state: () => {
    console.log(moment().get('year'))
    
    return {
      semesterYearList: [],
      currentSemesterYear: moment().get('year')
    }
  },
  getters: {},
  actions: {
    getOptions() {
      let options: Array<{label: string, value: string}> = [];
      
      for (let y=this.currentSemesterYear; y>=2021; y--) {
        options.push({
          label: y.toString(),
          value: y.toString()
        });
      }

      return options
    }
  }
});