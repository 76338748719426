<template>
<div class="settings-page">
  <div class="page__header">
    <div class="header-title">問卷調查</div>
  </div>

  <div class="page__body" :style="{ marginBottom: '36px' }">
    <a-tabs
      v-model:activeKey="selectedKey"
      @change="handleTabChange"
      type="card"
      :style="{ marginBottom: '12px' }"
    >
      <a-tab-pane key="lession_pre" tab="課前調查" />
      <a-tab-pane key="lession_post" tab="課後調查" />
    </a-tabs>

    <a-row v-if="!loading">
      <!-- 問卷調查欄位設定 -->
      <a-col :lg="12" :xs="24">
        <a-form>
          <template v-for="(survey, surveyIdx) in formState.contents">
            <a-form-item>
              <a-row>
                <a-col :span="12">
                  <span :style="{ fontSize: '1.1rem', fontWeight: '500', marginRight: '12px' }">調查項目 {{ surveyIdx+1 }}.</span>
                  <a-tooltip>
                    <template #title>刪除</template>
                    <MinusCircleOutlined
                      v-if="formState.contents.length > 1"
                      :disabled="formState.contents.length === 1 || updating"
                      @click="removeOneSurvey(surveyIdx)"
                    />
                  </a-tooltip>
                </a-col>

                <a-col :span="12" align="right">
                  <a-tooltip>
                    <template #title>上移</template>
                    <ArrowUpOutlined
                      v-if="surveyIdx > 0"
                      @click="moveUpSurvey(surveyIdx)"
                      :disabled="updating"
                      class="mt-2"
                    />
                  </a-tooltip>

                  <a-tooltip>
                    <template #title>下移</template>
                    <ArrowDownOutlined
                      v-if="surveyIdx != formState.contents.length-1"
                      @click="moveDownSurvey(surveyIdx)"
                      :disabled="updating"
                      class="mt-2 ml-1"
                    />
                  </a-tooltip>
                </a-col>
              </a-row>
            </a-form-item>

            <a-space
              style="display: flex;"
              align="baseline"
            >
              <a-form-item
                label="欄位名稱"
                :rules="[{ required: true }]"
              >
                <a-input
                  v-model:value="survey.name"
                  placeholder="例: 血壓"
                  :disabled="updating"
                />
              </a-form-item>

              <a-form-item
                label="欄位格式"
                :rules="[{ required: true }]"
              >
                <a-select
                  v-model:value="survey.type"
                  :options="formContentTypeOptions"
                  @change="changeContentType(surveyIdx)"
                  :style="{ minWidth: '95px' }"
                  :disabled="updating"
                ></a-select>
              </a-form-item>
            </a-space>
            
            <!-- TODO: 設定不同 type -->
            <a-row :gutter="12">
              <template v-if="survey.type == 'radio'">
                <a-col :span="24">
                  <a-form-item>
                    <div
                      class="ml-4 mb-2"
                      v-for="(selectionItem, selectionItemIdx) in survey.selection_items"
                    >
                      <span class="mdi mdi-radiobox-blank mr-2" :style="{ fontSize: '1rem' }" />
                      <a-input
                        v-model:value="selectionItem.label"
                        :placeholder="'選項 '+(selectionItemIdx+1)"
                        :disabled="updating"
                        :bordered="false"
                        :style="{ width: '200px', borderBottom: '1px solid #d9d9d9' }"
                      />
                      <a-tooltip placement="right">
                        <template #title>
                          <span>刪除選項</span>
                        </template>
                        <a-button type="link" shape="circle" :style="{ color: '#757575' }" @click="deleteSurveySelectionItem(surveyIdx, selectionItemIdx)">
                          <template #icon>
                            <span class="mdi mdi-close" />
                          </template>
                        </a-button>
                      </a-tooltip>
                    </div>

                    <div class="ml-4">
                      <span class="mdi mdi-radiobox-blank mr-2" :style="{ fontSize: '1rem' }" />
                      <a-button
                        @click="addSurveySelectionItem(surveyIdx)"
                        type="text" size="small"
                      >新增選項</a-button>
                    </div>
                  </a-form-item>
                </a-col>
              </template>

              <template v-if="survey.type == 'checkbox'">
                <a-col :span="24">
                  <a-form-item>
                    <div
                      class="ml-4 mb-2"
                      v-for="(selectionItem, selectionItemIdx) in survey.selection_items"
                    >
                      <span class="mdi mdi-checkbox-blank-outline mr-2" :style="{ fontSize: '1rem' }" />
                      <a-input
                        v-model:value="selectionItem.label"
                        :placeholder="'選項 '+(selectionItemIdx+1)"
                        :disabled="updating"
                        :bordered="false"
                        :style="{ width: '200px', borderBottom: '1px solid #d9d9d9' }"
                      />
                      <a-tooltip placement="right">
                        <template #title>
                          <span>刪除選項</span>
                        </template>
                        <a-button type="link" shape="circle" :style="{ color: '#757575' }" @click="deleteSurveySelectionItem(surveyIdx, selectionItemIdx)">
                          <template #icon>
                            <span class="mdi mdi-close" />
                          </template>
                        </a-button>
                      </a-tooltip>
                    </div>

                    <div class="ml-4">
                      <span class="mdi mdi-checkbox-blank-outline mr-2" :style="{ fontSize: '1rem' }" />
                      <a-button
                        @click="addSurveySelectionItem(surveyIdx)"
                        type="text" size="small"
                      >新增選項</a-button>
                    </div>
                  </a-form-item>
                </a-col>
              </template>

              <template v-if="survey.type == 'slider'">
                <a-col :span="12">
                  <a-form-item label="最小值">
                    <a-input
                      v-model:value="survey.rule.min"
                      type="nubmer"
                      placeholder="例: 1"
                      :disabled="updating"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="最大值">
                    <a-input
                      v-model:value="survey.rule.max"
                      type="nubmer"
                      placeholder="例: 5"
                      :disabled="updating"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="最小描述">
                    <a-input
                      v-model:value="survey.rule.min_label"
                      placeholder="例: 普普通通"
                      :disabled="updating"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="最大描述">
                    <a-input
                      v-model:value="survey.rule.max_label"
                      placeholder="例: 非常專心"
                      :disabled="updating"
                    />
                  </a-form-item>
                </a-col>
              </template>
            </a-row>
            <!-- TODO: 設定不同 type -->

            <a-row>
              <a-col :span="6">
                <a-form-item name="remember">
                  <a-checkbox
                    v-model:checked="survey.has_unit"
                    :disabled="updating"
                    @change="changeContentUnit(surveyIdx)"
                  >有單位</a-checkbox>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item
                  v-if="survey.has_unit"
                  label="單位名稱"
                >
                  <a-input
                    v-model:value="survey.unit"
                    placeholder="例: 下/分"
                    :disabled="updating"
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <!-- <a-form-item label="必填">
              <a-switch v-model:checked="survey.required" :disabled="updating" />
            </a-form-item> -->

            <a-divider :style="{ margin: '12px 0' }" v-if="surveyIdx < formState.contents.length-1" />
          </template>
        </a-form>

        <a-button type="dashed" style="width: 60%" :disabled="updating" @click="insertOneSurvey">
          <PlusOutlined />
          新增調查項目
        </a-button>

        <div class="mt-10">
          <a-button
            key="submit"
            type="primary"
            size="large"
            :loading="updating"
            @click="handleOk"
          >確認更新</a-button>
        </div>
      </a-col>
      <!-- 問卷調查欄位設定 -->
      
      <a-divider v-if="device.mobile" />

      <!-- 使用者畫面預覽 -->
      <a-col
        :lg="12" :xs="24"
        :style="{ padding: device.mobile ? '0' : '0 24px' }"
      >
        <div class="mb-2" :style="{ fontSize: device.mobile ? '1.3rem' : '1.1rem', fontWeight: '400' }">使用者畫面預覽</div>

        <div class="prview-card-list">
          <div class="letwecare-survey-card" v-for="survey in formState.contents">
            <div class="card-title">{{ survey.name }}</div>
            <div class="card-body" align="center">
              <template v-if="survey.type == 'number'">
                <a-input-number
                  class="body-number-input"
                  size="large"
                  :min="1"
                />
              </template>

              <template v-if="survey.type == 'text-field'">
                <a-input
                  class="body-input"
                  size="large"
                />
              </template>

              <template v-if="survey.type == 'radio'">
                <a-radio-group v-model:value="survey.value">
                  <a-radio  
                    v-for="opt in survey.selection_items"
                    :value="opt.label"
                    :style="{ display: 'flex', height: '40px', lineHeight: '40px', fontSize: '1.3rem' }"
                  >{{ opt.label }}</a-radio>
                </a-radio-group>
              </template>

              <template v-if="survey.type == 'checkbox'">
                <a-checkbox-group v-model:value="survey.value">
                  <a-checkbox  
                    v-for="opt in survey.selection_items"
                    :value="opt.label"
                    :style="{ display: 'flex', height: '40px', lineHeight: '40px', fontSize: '1.3rem', marginLeft: 0 }"
                  >{{ opt.label }}</a-checkbox>
                </a-checkbox-group>
              </template>

              <template v-if="survey.type == 'slider'">
                <span :style="{ display: 'inline-block', fontSize: '1.8rem', fontWeight: '500', marginBottom: '8px' }">{{ survey.value }}</span>
                <a-row>
                  <a-col :span="12" align="left">
                    {{ survey.rule.min_label }}
                  </a-col>
                  <a-col :span="12" align="right">
                    {{ survey.rule.max_label }}
                  </a-col>
                  <a-col :span="20" :offset="2">
                    <a-slider
                      v-model:value="survey.value"
                      :min="survey.rule.min"
                      :max="survey.rule.max"
                    />
                  </a-col>
                </a-row>
              </template>
              
              <span class="body-unit" v-if="survey.unit">{{ survey.unit }}</span>
            </div>
          </div>
        </div>
      </a-col>
      <!-- 使用者畫面預覽 -->
    </a-row>

    <!-- Loading -->
    <template v-if="loading">
      <div align="center" :style="{ padding: '40px 0' }">
        <a-spin tip="載入資料中..." :style="{ margin: '0 auto' }" />
      </div>
    </template>
    <!-- Loading -->
  </div>
</div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { createDeviceDetector } from 'next-vue-device-detector'
import { message } from 'ant-design-vue'
import { MinusCircleOutlined, PlusOutlined, CloseOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons-vue'
import api from '@/apis'
import CopyUtil from '@/utils/CopyUtil'

export default defineComponent({
  components: {
    MinusCircleOutlined,
    PlusOutlined,
    CloseOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined
  },
  setup() {
    const route = useRoute();
    const device = createDeviceDetector();

    /* Tab */
    const selectedKey = ref('lession_pre');
    const handleTabChange = (async () => {
      loading.value = true;
      
      await getCourseSurveyData();
      formState.value = { ...surveyData.value[selectedKey.value] };

      loading.value = false;
    });

    /* Loading */
    const loading = ref(false);
    const updating = ref(false);

    /* Data */
    const courseData = ref({});
    const initialSurveyData = {
      lession_pre: {},
      lession_post: {}
    }
    const surveyData = ref({});

    /* Form State */
    const formState = ref({});
    const formSurveyContent = {
      name: '',
      type: 'number',
      rule: null,
      selection_items: [],
      has_unit: false,
      unit: '',
      required: false
    }
    const contentTypeRule = {
      slider: {
        min: 1,
        max: 5,
        min_label: '',
        max_label: ''
      }
    }

    const changeContentType = (surveyIdx) => {
      const type = formState.value.contents[surveyIdx].type;

      console.log(formState.value.contents[surveyIdx].selection_items)

      if (type == 'radio') {
        if (!formState.value.contents[surveyIdx].selection_items.length) {
          formState.value.contents[surveyIdx].selection_items.push({
            label: ''
          });
        }
      }
      else if (type == 'checkbox') {
        if (!formState.value.contents[surveyIdx].selection_items.length) {
          formState.value.contents[surveyIdx].selection_items.push({
            label: ''
          });
        }
      }
      else if (type == 'slider') {
        formState.value.contents[surveyIdx].rule = { ...contentTypeRule[type] };
      }

      console.log(formState.value.contents);
    }

    const changeContentUnit = (surveyIdx) => {
      formState.value.contents[surveyIdx].unit = '';
    }
    
    // survey selection-items
    const addSurveySelectionItem = (surveyIdx) => {
      formState.value.contents[surveyIdx].selection_items.push({
        label: ''
      });
    }

    const deleteSurveySelectionItem = (surveyIdx, selectionItemIdx) => {
      formState.value.contents[surveyIdx].selection_items.splice(selectionItemIdx, 1);
    }

    /* Api */
    const getCourseData = (async () => {
      const response = await api.v1.course.findOne(route.params.course_id);
      courseData.value = response.data.result;
    });

    const getCourseSurveyData = (async () => {
      surveyData.value = { ...initialSurveyData };

      const response = await api.v1.course.surveyList(route.params.course_id);
      const result = response.data.result;

      result.forEach(item => {
        if (Object.keys(surveyData.value).includes(item.type)) {
          surveyData.value[item.type] = {
            id: item.id,
            contents: [...item.contents]
          }
        }
      });

      // 填入空白模板
      Object.keys(surveyData.value).forEach(key => {
        if (Object.keys(surveyData.value[key]).length == 0) {
          surveyData.value[key] = {
            id: null,
            contents: [CopyUtil.iterateObjectDeepClone(formSurveyContent)]
          }
        }
      });

      console.log(surveyData.value)
    });

    /* Mounted */
    onMounted(async () => {
      await getCourseData();
      await handleTabChange();
    });

    return {
      device,

      /* Tab */
      selectedKey,
      handleTabChange,

      /* Loading */
      loading,
      updating,

      /* Data */
      courseData,
      surveyData,

      /* Form State */
      formState,
      formSurveyContent,
      formContentTypeOptions: ref([{ // number|text-field|radio|checkbox|slider
        label: '數字',
        value: 'number'
      }, {
        label: '簡答文字',
        value: 'text-field'
      }, {
        label: '單選題',
        value: 'radio'
      }, {
        label: '多選題',
        value: 'checkbox'
      }, {
        label: '滑動條',
        value: 'slider'
      }]),
      changeContentType,
      changeContentUnit,
      // survey selection-items
      addSurveySelectionItem,
      deleteSurveySelectionItem,

      /* Utils */
      CopyUtil
    }
  },
  methods: {
    async handleOk() {
      console.log(this.surveyData[this.selectedKey]);

      const err = this._checkRequired();
      if (err) {
        message.error(err);
        return
      }

      this.updating = true;

      // 判斷操作: 新增資料 或 更新資料
      let operateFlag = undefined; // insert | update
      if (this.surveyData[this.selectedKey].id == null) {
        operateFlag = 'insert';
      } else {
        operateFlag = 'update';
      }

      // 處理新增資料
      if (operateFlag == 'insert') {
        try {
          await api.v1.course.insertOneSurvey(this.$route.params.course_id, {
            type: this.selectedKey,
            contents: this.surveyData[this.selectedKey].contents
          });
          message.success('更新問卷調查成功')
        } catch (error) {
          console.log(error);
          message.error('更新問卷調查發生錯誤，請稍後再試')
        } finally {
          this.handleTabChange();
          this.updating = false;
        }
      }

      // 處理更新資料
      if (operateFlag == 'update') {
        try {
          await api.v1.course.updateOneSurvey(this.$route.params.course_id, {
            id: this.surveyData[this.selectedKey].id,
            contents: this.surveyData[this.selectedKey].contents
          });
          message.success('更新問卷調查成功')
        } catch (error) {
          console.log(error);
          message.error('更新問卷調查發生錯誤，請稍後再試')
        } finally {
          this.handleTabChange();
          this.updating = false;
        }
      }
    },
    _checkRequired() {
      let err = '';

      const contents = this.surveyData[this.selectedKey].contents;
      for (let i=0; i<contents.length; i++) {
        const content = contents[i];
        if (content.name == '') {
          err = `請輸入 調查項目${i+1} 的欄位名稱`;
          break
        }
        if (content.type == '') {
          err = `請輸入 調查項目${i+1} 的欄位格式`;
          break
        }

        if (content.type == 'slider') {
          if (isNaN(content.rule.min)) {
            err = '滑動條最小值不可包含文字';
            break
          }
          if (content.rule.min === '') {
            err = '滑動條最小值不可為空';
            break
          }
          if (content.rule.min < 0) {
            err = '滑動條最小值不可為負值';
            break
          }
          if (isNaN(content.rule.max)) {
            err = '滑動條最大值不可包含文字';
            break
          }
          if (content.rule.max === '') {
            err = '滑動條最大值不可為空';
            break
          }
          if (content.rule.max < 0) {
            err = '滑動條最大值不可為負值';
            break
          }
          if (content.rule.max === 0) {
            err = '滑動條最大值不可為零';
            break
          }
          if (content.rule.max <= content.rule.min) {
            err = '滑動條最大值不可小於或等於最小值';
            break
          }
        }
      }

      return err
    },

    /* Form State */
    insertOneSurvey() {
      this.formState.contents.push(CopyUtil.iterateObjectDeepClone(this.formSurveyContent));
    },
    removeOneSurvey(index) {
      this.formState.contents.splice(index, 1);
    },
    moveUpSurvey(index) {
      this.formState.contents[index] = this.formState.contents.splice(index-1, 1, this.formState.contents[index])[0];
    },
    moveDownSurvey(index) {
      this.formState.contents[index] = this.formState.contents.splice(index+1, 1, this.formState.contents[index])[0];
    },
  }
})
</script>

<style lang="scss">
.prview-card-list {
  padding: 24px 12px 24px 12px;
  background: #EAEAEA;

  // .preview-card {
  //   background: white;
  //   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  //   border-radius: 8px;
  //   width: 80%;
  //   padding: 4px;
  //   margin: 0 auto 20px auto;

  //   .card-title {
  //     min-height: 50px;
  //     padding: 8px 20px;
  //     border-bottom: 1px solid #E0E0E0;
  //     font-weight: bold;
  //     color: #212121;
  //     font-size: 1.3rem;
  //     text-align: center;
  //   }

  //   .card-body {
  //     padding: 24px 20px;

  //     .body-input {
  //       width: 80%;
  //       min-width: 150px;
  //       font-size: 1.5rem;
  //       height: 64px;
  //     }

  //     .body-number-input {
  //       width: 35%;
  //       min-width: 100px;
        
  //       input {
  //         display: inline-block;
  //         font-size: 2.3rem;
  //         width: 100px;
  //         height: 64px;
  //       }
  //     }

  //     .body-unit {
  //       color: #757575;
  //       font-size: 1.1rem;
  //       font-weight: 500;
  //       margin-left: 8px;
  //     }
  //   }

  //   .card-actions {
  //     padding: 12px 20px;

  //     .action-btn {
  //       width: 100%;
  //       font-size: 1.2rem;
  //       height: 40px;
  //     }
  //   }
  // }
}
</style>