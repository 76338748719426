<template>
<div class="settings-page">
  <div class="page__header">
    <div class="header-title">進階設定</div>
  </div>

  <div class="page__body">
    <a-row>
      <a-col :lg="16" :xs="24">
        <a-alert message="刪除班級" type="error">
          <template #description>
            <p>
              此動作會刪除 <a-typography-text keyboard>{{ courseData.name }}</a-typography-text> 其班級底下的課堂與學生資料。
            </p>
            <a-button
              type="primary"
              danger
              @click="openDeleteConfirmModal"
              :loading="deleteLoading"
              :style="{ backgroundColor: '#ff4d4f' }"
            >刪除班級</a-button>
          </template>
        </a-alert>
      </a-col>
    </a-row>
  </div>

  <DeleteConfirmModal
    :visible="deleteConfirmModal.visible"
    titleText="班級"
    :confirmationCode="deleteConfirmModal.code"
    @cancel="handleDeleteConfirmModalClose"
    @confirm="handleDeleteConfirmModalConfirm"
  />
</div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import api from '@/apis'
import DeleteConfirmModal from '@/components/ui/DeleteConfirmModal'

export default defineComponent({
  components: {
    DeleteConfirmModal
  },
  setup() {
    const route = useRoute();

    /* Data */
    const courseData = ref({});

    /* Api */
    const getCourseData = (async () => {
      const response = await api.v1.course.findOne(route.params.course_id);
      courseData.value = response.data.result;
    });

    /* Mounted */
    onMounted(async () => {
      await getCourseData();
    });

    return {
      /* Data */
      courseData
    }
  },
  data() {
    return {
      deleteLoading: false,
      deleteConfirmModal: {
        visible: false,
        code: null
      }
    }
  },
  methods: {
    /* Delete Confirm */
    openDeleteConfirmModal() {
      this.deleteConfirmModal.code = this.courseData.name;
      this.deleteConfirmModal.visible = true;
    },
    handleDeleteConfirmModalClose() {
      this.deleteConfirmModal.visible = false;
    },
    async handleDeleteConfirmModalConfirm() {
      this.deleteConfirmModal.visible = false;
      this.deleteLoading = true;

      // delete course
      try {
        await api.v1.course.deleteOne(this.courseData.id);
      } catch (error) {
        console.log(error);
        this.deleteLoading = false;
        message.error('刪除班級發生錯誤');
        return
      } finally {
        this.deleteLoading = false;
      }

      message.success('刪除班級成功');
      this.$router.push({ name: 'AppCourses' });
    }
  }
})
</script>