<template>
<div class="settings-page">
  <div class="page__header">
    <div class="header-title">班級資料</div>
  </div>

  <div class="page__body">
    <a-form
      :model="courseData"
      layout="vertical"
      autocomplete="off"
    >
      <a-row>
        <a-col :lg="12" :xs="24">
          <a-form-item
            label="班級期別"
            name="semester"
            :rules="[{ required: true, message: '請選擇班級期別' }]"
          >
            <a-select
              v-model:value="courseData.semester"
              :options="semesterOptions"
              :disabled="updating"
              size="large"
            ></a-select>
          </a-form-item>
        </a-col>
      </a-row>
      
      <a-row>
        <a-col :lg="12" :xs="24">
          <a-form-item
            label="班級名稱"
            name="name"
            :rules="[{ required: true, message: '請輸入班級名稱' }]"
          >
            <a-input
              v-model:value="courseData.name"
              :disabled="updating"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :lg="12" :xs="24">
          <a-form-item
            label="班級簡介"
            name="description"
            :rules="[{ required: true, message: '請輸入班級簡介' }]"
          >
            <a-input
              v-model:value="courseData.description"
              :disabled="updating"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :lg="12" :xs="24">
          <a-form-item
            label="教室名稱"
            name="classroom"
            :rules="[{ required: true, message: '請輸入教室名稱' }]"
          >
            <a-input
              v-model:value="courseData.classroom"
              :disabled="updating"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>

  <div class="page__actions">
    <a-button
      key="submit"
      type="primary"
      size="large"
      :loading="updating"
      @click="handleOk"
    >確認更新</a-button>
  </div>
</div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { useSemesterStore } from '@/store/semester'
import api from '@/apis'

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();

    /* Store */
    const semesterStore = useSemesterStore();

    /* Loading */
    const updating = ref(false);

    /* Data */
    const courseData = ref({});

    /* Options */
    const semesterOptions = ref([]);

    /* Api */
    const getCourseData = (async () => {
      const response = await api.v1.course.findOne(route.params.course_id);
      courseData.value = response.data.result;
    });

    /* Mounted */
    onMounted(async () => {
      semesterOptions.value = await semesterStore.getOptions();
      await getCourseData();
    });

    return {
      /* Loading */
      updating,
      
      /* Data */
      courseData,

      /* Options */
      semesterOptions
    }
  },
  methods: {
    async handleOk() {
      const err = this._checkRequried();
      if (err) {
        message.error(err);
        return
      }

      this.updating = true;

      try {
        await api.v1.course.updateOne(this.courseData.id, {
          semester: this.courseData.semester,
          name: this.courseData.name,
          description: this.courseData.description,
          classroom: this.courseData.classroom,
        });
      } catch (error) {
        console.log(error);
        message.error('更新班級資料失敗');
        this.$emit('cancel');
        return
      } finally {
        this.updating = false;
      }

      message.success('更新班級資料成功');
    },

    /* Private func */
    _checkRequried() {
      let err = '';
      
      if (!this.courseData.name) {
        err = '請輸入班級名稱';
      }
      else if (!this.courseData.description) {
        err = '請輸入班級簡介';
      }
      else if (!this.courseData.classroom) {
        err = '請輸入教室名稱';
      }

      return err
    }
  }
})
</script>