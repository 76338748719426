<template>
  <a-modal
    :visible="visible"
    :title="`確認刪除${titleText}`"
    :maskClosable="false"
    :closable="false"
  >
    <a-alert class="mb-5" message="您正在執行刪除動作，一但刪除後便無法恢復此筆資料。" banner />

    <div class="mb-2">若您確定要刪除此筆資料，請在下方輸入框再次輸入您欲刪除的項目名稱</div>
    <a-input v-model:value="code" :placeholder="confirmationCode"></a-input>

    <template #footer>
      <a-button
        key="submit"
        type="primary"
        danger
        :disabled="!nameCheck"
        :loading="loading"
        @click="handleOk"
      >確認刪除</a-button>
      <a-button key="cancel" type="text" :disabled="loading" @click="handleCancel">取消</a-button>
    </template>
  </a-modal>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    titleText: {
      type: String,
      default: ''
    },
    confirmationCode: {
      type: String,
      default: ''
    }
  },
  components: {},
  setup() {
    return {}
  },
  data() {
    return {
      code: ''
    }
  },
  computed: {
    nameCheck() {
      if (this.code == this.confirmationCode) {
        return true
      }
      return false
    }
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
      this.code = '';
    },
    handleOk() {
      this.$emit('confirm');
      this.code = '';
    }
  }
})
</script>